import {} from 'domains/Customer/hooks'

import { COLLECTIONS } from '__constants__'
import { useDocument } from 'services/api/firebase'
import { useLoading } from 'hooks'
import { useMemo } from 'react'

const useGetCustomerInitialValues = (id) => {
  /* Fetching data using useDocument hook */
  const [customer, customerLoading] = useDocument({
    ref: id ? COLLECTIONS.USERS + '/' + id : null
  })

  /* Merge all fetched data */
  const initialValues = useMemo(
    () => ({
      customer
    }),
    [customer]
  )

  /* Merge all loading states */
  const loadings = useMemo(
    () => (id ? [!customer, customerLoading] : []),
    [id, customer, customerLoading]
  )

  /* Getting loading state using merged loadings */
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetCustomerInitialValues
