import { Box, Input, PhoneInput, Switch, Text } from '@qonsoll/react-design'

import { AvatarUploader } from 'components'
import { CustomerExtraTickets } from './components'
import { Form } from 'antd'
import { MAX_LENGTH_FIELD } from '__constants__'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const CustomerSimpleForm = (props) => {
  const { group } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  return (
    <>
      <Form.Item
        name={[...group, 'avatarUrl']}
        rules={[
          {
            required: false,
            message: t('Please enter your customer avatar url!')
          }
        ]}>
        <AvatarUploader />
      </Form.Item>
      <Form.Item
        label={t('First name')}
        name={[...group, 'firstName']}
        rules={[
          {
            required: true,
            message: t('Please enter your customer first name!')
          },
          {
            max: MAX_LENGTH_FIELD,
            message: t('customer first name is too long!')
          }
        ]}>
        <Input placeholder={t('Please enter your customer first name')} />
      </Form.Item>
      <Form.Item
        label={t('Last name')}
        name={[...group, 'lastName']}
        rules={[
          {
            required: true,
            message: t('Please enter your customer last name!')
          },
          {
            max: MAX_LENGTH_FIELD,
            message: t('customer last name is too long!')
          }
        ]}>
        <Input placeholder={t('Please enter your customer last name')} />
      </Form.Item>
      <Form.Item
        label={t('Email')}
        name={[...group, 'email']}
        rules={[
          {
            required: true,
            message: t('Please enter your user email!')
          },
          { max: MAX_LENGTH_FIELD, message: t('user email is too long!') }
        ]}>
        <Input placeholder={t('Please enter your user email')} />
      </Form.Item>
      <Form.Item
        label={t('Phone')}
        name={[...group, 'phone']}
        rules={[
          {
            required: false,
            message: t('Please enter your user phone!')
          }
        ]}>
        <PhoneInput
          country="no"
          specialLabel=""
          placeholder={t('Please enter your user phone')}
        />
      </Form.Item>
      <Form.Item>
        <CustomerExtraTickets />
      </Form.Item>
      <Form.Item name={[...group, 'isUnlimitedUser']} valuePropName="checked">
        <SwitchWrapper />
      </Form.Item>
    </>
  )
}

const SwitchWrapper = (props) => {
  /* Getting translations instance */
  const { t } = useTranslations()

  return (
    <Box display="flex" alignItems="center">
      <Switch {...props} />
      <Text
        ml={3}
        color="var(--ql-form-item-label-color)"
        fontSize="var(--ql-form-item-label-font-size-base)">
        {t('Unlimited access to all features')}
      </Text>
    </Box>
  )
}

CustomerSimpleForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  showTitle: PropTypes.bool,
  form: PropTypes.any,
  group: PropTypes.array
}

export default CustomerSimpleForm
