import { Col, Row } from '@qonsoll/react-design'

import { HelpAdvancedForm } from 'domains/Help/components'
import { PageLayout } from 'components'
import { Spinner } from 'components'
import { useGetHelpInitialValues } from 'domains/Help/hooks'
import { useParams } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'

const HelpEditPage = (props) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting params from router */
  const params = useParams()
  /* Getting id from params */
  const { helpId } = params

  /* Fetching help initial values */
  const [initialValues, loading] = useGetHelpInitialValues(helpId)

  return (
    <PageLayout
      title={t('Help edit')}
      subTitle={t(
        'Follow the instructions bellow to get results in shortest possible time'
      )}>
      <Row noGutters>
        {loading ? (
          <Col>
            <Spinner height="70vh" text={t('Help loading')} />
          </Col>
        ) : (
          <Col cw={[12, 12, 10, 8, 7]}>
            <HelpAdvancedForm initialData={initialValues} />
          </Col>
        )}
      </Row>
    </PageLayout>
  )
}

export default HelpEditPage
