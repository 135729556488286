import { memo, useMemo } from 'react'
import { useEffect, useRef, useState } from 'react'

import { Box } from '@qonsoll/react-design'
import PropTypes from 'prop-types'

/**
 * If the element is not visible, it will be observed by the IntersectionObserver. If it is visible,
 * the observer will be disconnected
 * @returns The first element is a boolean that tells us if the element is visible or not. The second
 * element is a ref that we can use to get the DOM node.
 */
const useOnScreen = () => {
  const ref = useRef()
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      const isIntersecting = entry.isIntersecting
      if (!isVisible && isIntersecting) {
        observer?.disconnect()
        setIsVisible(true)
      }
    })

    if (!isVisible) observer.observe(ref.current)
    // Remove the observer as soon as the component is unmounted
    return () => observer?.disconnect()
  }, [isVisible])

  return [isVisible, ref]
}

const DEFAULT_MIN_HEIGHT = '100px' // min height of smallest list item in app

const RenderIfVisible = ({ children, defaultHeight }) => {
  const [visible, ref] = useOnScreen()

  const minHeight = useMemo(
    () => defaultHeight || DEFAULT_MIN_HEIGHT,
    [defaultHeight]
  )

  return (
    <Box ref={ref} minHeight={minHeight}>
      {visible ? (
        <div className="animate__animated animate__fadeIn ">{children}</div>
      ) : null}
    </Box>
  )
}

RenderIfVisible.propTypes = {
  children: PropTypes.any,
  defaultHeight: PropTypes.string
}

export default memo(RenderIfVisible)
