import { Aside, Box } from '@qonsoll/react-design'

import styled from 'styled-components'

export const StyledAside = styled(Aside)`
  transition: 1s;
  height: 100vh;
`
export const MenuCollapseWrapper = styled(Box)`
  top: 4px;
  position: absolute;
  left: ${({ asideLeftCollapsed }) =>
    asideLeftCollapsed
      ? 'calc(var(--ql-aside-collapsed-width) - 12px)'
      : 'calc(var(--ql-aside-width) + 4px)'};
  transition: 1s;
  z-index: 100;
`
