import PropTypes from 'prop-types'
import { StyledSelect } from './LanguageSelect.styled'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

const LanguageSelect = (props) => {
  const { collapse } = props

  const { setCurrentLanguage, language, languages, loading } = useTranslations()

  const handleChange = ({ value }) => setCurrentLanguage(value)

  const options = useMemo(
    () =>
      languages
        .filter(({ value }) => value !== language)
        .map(({ value, label }) => ({ label, value })),
    [language, languages]
  )

  const defaultLanguage = useMemo(() => {
    const lang = languages.find(({ value }) => value === language)
    return collapse
      ? { label: lang.value.toUpperCase(), value: lang.value }
      : { label: lang.label, value: lang.value }
  }, [language, languages, collapse])

  return (
    <StyledSelect
      labelInValue
      showArrow={!collapse}
      size={collapse ? 'small' : 'default'}
      style={{ textAlign: 'center' }}
      onSelect={handleChange}
      value={defaultLanguage}
      loading={loading}>
      {options.map(({ label, value }) => (
        <StyledSelect.Option key={value} value={value}>
          {collapse ? value?.toUpperCase() : label}
        </StyledSelect.Option>
      ))}
    </StyledSelect>
  )
}

LanguageSelect.propTypes = {
  collapse: PropTypes.bool
}

export default LanguageSelect
