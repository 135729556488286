import { get, ref, set } from 'firebase/database'
import { useCallback, useState } from 'react'

import { COLLECTIONS } from '__constants__'
import { database } from 'services/firebase'
import { message } from 'antd'
import { useTranslations } from 'contexts/Translation'

const useUpdateCustomerExtraTicketsAmount = (
  customerId,
  previousTicketsAmount
) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const { t } = useTranslations()

  const updateExtraTickets = useCallback(
    async (ticketsAmount) => {
      try {
        setLoading(true)
        const ticketsRef = ref(database, `${COLLECTIONS.TICKETS}/${customerId}`)
        const ticketsSnapshot = await get(ticketsRef)
        if (ticketsSnapshot.exists()) {
          const ticketsData = ticketsSnapshot.val()
          await set(ticketsRef, {
            ...ticketsData,
            extra: Number(previousTicketsAmount) + Number(ticketsAmount)
          })
        } else
          await set(ticketsRef, {
            extra: Number(previousTicketsAmount) + Number(ticketsAmount)
          })
      } catch (err) {
        message.error(
          t(
            'Error during extra tickets add operation. Check logs and contact administrator with this proper'
          )
        )
        //eslint-disable-next-line
        console.error('Error:', err)
        setError(err)
      } finally {
        setLoading(false)
      }
    },
    [customerId, previousTicketsAmount, t]
  )

  return [updateExtraTickets, loading, error]
}

export default useUpdateCustomerExtraTicketsAmount
