import { CSVLink } from 'react-csv'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const DownloadEmailListButton = ({ customers }) => {
  /* Getting translations instance */
  const { t } = useTranslations()

  /* The CSV file column headers */
  const headers = [
    { label: 'First Name', key: 'firstName' },
    { label: 'Last Name', key: 'lastName' },
    { label: 'Email', key: 'email' },
    { label: 'Phone', key: 'phone' }
  ]

  const disabled = !customers ? true : 0 >= customers?.length

  /* Formatted array to download in CSV file */
  const csvData = disabled
    ? []
    : customers
        ?.filter((customer) => customer?.email)
        .map((customer) => {
          return {
            firstName: customer?.firstName || '',
            lastName: customer?.lastName || '',
            email: customer?.email,
            phone: customer?.phone || ''
          }
        })

  return (
    <CSVLink
      filename="email_list"
      enclosingCharacter=""
      disabled={disabled}
      className="button ant-btn ant-btn-primary"
      headers={headers}
      data={csvData}>
      {t('Download email list')}
    </CSVLink>
  )
}

DownloadEmailListButton.propTypes = {
  customers: PropTypes.array
}

export default DownloadEmailListButton
