import { useMemo, useState } from 'react'

import { CURRENT_APP } from 'modules/admin-module/domains/Translation/__constants__'
import { CustomTabs } from './Translations.styles'
import { LocalizationForm } from 'modules/admin-module/domains/Translation/components'
import { PageLayout } from 'components'
import { Tabs } from 'antd'
import { useTranslations } from 'contexts/Translation'

const { TabPane } = Tabs

const environment = process.env.REACT_APP_ENVIRONMENT

/* NOTE: ADD YOUR TRANSLATIONS APPS HERE */
const apps =
  environment === 'DEVELOPMENT'
    ? [
        { label: 'Admin dev', value: CURRENT_APP },
        { label: 'Customer dev', value: 'onkel-skrue-customer' }
      ]
    : [
        { label: 'Admin', value: CURRENT_APP },
        { label: 'Customer', value: 'onkel-skrue-customer' }
      ]

const Translations = () => {
  const { t } = useTranslations()

  const [isPageLoading, setIsPageLoading] = useState(true)

  const handleLoadingChange = (newValue = false) => {
    if (isPageLoading !== newValue) setIsPageLoading(newValue)
  }

  const height = useMemo(
    () => (isPageLoading ? '100%' : 'initial'),
    [isPageLoading]
  )

  return (
    <PageLayout
      title={t('Translations')}
      subTitle={t(
        'Follow the instructions bellow to get results in shortest possible time'
      )}>
      <CustomTabs defaultActiveKey={CURRENT_APP} size="large" height={height}>
        {apps.map(({ label, value }) => (
          <TabPane tab={label} key={value}>
            <LocalizationForm
              appName={value}
              isPageLoading={isPageLoading}
              handleLoadingChange={handleLoadingChange}
            />
          </TabPane>
        ))}
      </CustomTabs>
    </PageLayout>
  )
}

export default Translations
