import {
  Avatar,
  Box,
  Button,
  Dropdown,
  Ribbon,
  Text,
  Title
} from '@qonsoll/react-design'
import { CardDropdown, SelectableCard } from 'components'
import { memo, useMemo } from 'react'
import {
  useCustomerBaseActions,
  useGetCustomerRentalCalculators
} from 'domains/Customer/hooks'

import { Icon } from 'components'
import { Menu } from 'antd'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'
import { useUserName } from 'hooks'

const CustomerSimpleView = (props) => {
  const { customer, onSelect, index, isSelected, actions } = props

  const { displayName, avatarText } = useUserName(customer)

  const userParams = useMemo(() => ({ userId: customer?._id }), [customer])
  const [projects] = useGetCustomerRentalCalculators(userParams)

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting base actions */
  const { onEdit, onDelete } = useCustomerBaseActions({
    customerId: customer?._id,
    actions
  })

  /* Handling selection of the item */
  const handleSelect = (e) => {
    e.stopPropagation()
    onSelect?.(customer?._id)
  }

  const projectsLength = projects?.length || 0

  const menuItems = [
    {
      key: 'edit',
      icon: 'Edit2Outlined',
      label: t('Edit'),
      onClick: onEdit
    },
    {
      key: 'remove',
      icon: 'Trash1Outlined',
      label: t('Remove'),
      danger: true,
      onClick: () => {
        onDelete?.()
      }
    }
  ]

  return (
    <CardDropdown
      onDelete={onDelete}
      onEdit={onEdit}
      onClick={handleSelect}
      document={customer}
      index={index}
      data-testid="customerListItem"
      {...actions}>
      <Ribbon
        text={t('Unlimited')}
        placement="start"
        bg="var(--ql-color-accent1)"
        top={customer.isProAccess ? '50px' : '20px'}
        display={customer.isUnlimitedUser ? 'block' : 'none'}>
        <Ribbon
          text={t('PRO')}
          placement="start"
          bg="var(--ql-color-accent1)"
          top={'20px'}
          display={customer.isProAccess ? 'block' : 'none'}>
          <SelectableCard isSelected={isSelected} position="relative">
            <Box position="absolute" right={2} top={2} zIndex={1}>
              <Dropdown
                style={{ marginLeft: 'auto' }}
                placement="bottomRight"
                overlay={
                  <Menu>
                    {menuItems.map((item) => (
                      <Menu.Item
                        key={item.key}
                        onClick={item.onClick}
                        disabled={item.disabled}
                        danger={item.danger}>
                        <Box display="flex" alignItems="center">
                          <Icon name={item.icon} size={16} mr={2} />
                          <Text ml={2}>{item.label}</Text>
                        </Box>
                      </Menu.Item>
                    ))}
                  </Menu>
                }>
                <Button
                  type="text"
                  icon={<Icon size={20} name="DotsVerticalOutlined" />}
                />
              </Dropdown>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Avatar
                mb={3}
                size={[120]}
                src={customer?.avatarUrl}
                style={{ alignItems: 'center', display: 'flex' }}>
                <Title type="secondary" fontWeight={500}>
                  {avatarText || <Icon name="User2Outlined" size={56} />}
                </Title>
              </Avatar>
            </Box>
            <Box>
              <Title textAlign="center" level={3}>
                {displayName}
              </Title>
              <Text textAlign="center" display="block" ellipsis variant="body2">
                {customer?.email}
              </Text>
              <Text textAlign="center" display="block" ellipsis variant="body2">
                {t('Projects')}: {projectsLength}
              </Text>
            </Box>
          </SelectableCard>
        </Ribbon>
      </Ribbon>
    </CardDropdown>
  )
}

CustomerSimpleView.propTypes = {
  customer: PropTypes.object,
  onSelect: PropTypes.func,
  index: PropTypes.number,
  isSelected: PropTypes.bool,
  actions: PropTypes.object
}

export default memo(CustomerSimpleView)
