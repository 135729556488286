import { Button, Col, Row } from '@qonsoll/react-design'
import { Icon, PageLayout } from 'components'
import { useFilter, useListView } from 'hooks'
import { useGetHelps, useHelpBaseActions } from 'domains/Help/hooks'

import { HelpList } from 'domains/Help/components'
import PropTypes from 'prop-types'
import { Spinner } from 'components'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

const HelpsAllPage = (props) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  /* State for controlling list view with localStorage remembering */
  const [listView] = useListView('help')
  /* Getting filter params */
  const filterParams = useFilter('help')
  /* Destructuring filter params */
  const { filterData } = filterParams
  /* Building filter query */
  const ref = useMemo(() => filterData, [filterData])
  /* Fetching helps */
  const [helps, loading] = useGetHelps(ref)
  /* Getting base actions */
  const listActions = useHelpBaseActions()

  /* Extracting base actions */
  const { onCreate } = listActions

  return (
    <PageLayout
      title={t('Help')}
      actions={
        <Button
          type="primary"
          onClick={onCreate}
          icon={<Icon size={24} fill="#fff" name="PlusOutlined" mr={2} />}>
          {t('Add question')}
        </Button>
      }
      subTitle={t(
        'Follow the instructions bellow to get results in shortest possible time'
      )}>
      <Row noGutters>
        {loading ? (
          <Col>
            <Spinner height="70vh" text={t('Help loading')} />
          </Col>
        ) : (
          <Col>
            <HelpList listView={listView} helps={helps} />
          </Col>
        )}
      </Row>
    </PageLayout>
  )
}

HelpsAllPage.propTypes = {
  isListWithCreate: PropTypes.bool,
  withHeader: PropTypes.bool
}

export default HelpsAllPage
