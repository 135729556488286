import { useEffect } from 'react'
import { Form } from 'antd'

const useInitializeHelpAdvancedForm = (initialData) => {
  /* Getting form instance */
  const [form] = Form.useForm()

  /* Setting initial data */
  useEffect(() => {
    if (initialData) {
      /* Getting initial values */
      const { help } = initialData

      /* Formatting date fields */
      const formValues = {
        help: {
          question: help?.['question'] ?? null,
          answer: help?.['answer'] ?? null
        }
      }

      /* Setting initial values to form instance */
      form.setFieldsValue(formValues)
    }
  }, [initialData, form])

  return { form }
}

export default useInitializeHelpAdvancedForm
