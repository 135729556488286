import { AddItemCard, RenderIfVisible } from 'components'
import { Box, Col, Row, Text, Button } from '@qonsoll/react-design'

import { CustomerSimpleView } from 'domains/Customer/components'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const CustomerList = (props) => {
  const {
    customers,
    isListWithCreate,
    listView,
    emptyProps,
    actions,
    cw = [12, 12, 6, 4, 3],
    additionalAction,
    additionalActionLoading = false,
    additionalText
  } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Counting the customers */
  const customersCount = customers?.length
  /* Checking if list is empty */
  const isEmpty = !customersCount
  /* Computing item width */
  const itemWidth = listView ? 12 : cw

  const showAdditionalButton = additionalAction && additionalText
  const topPanelElementWidth = showAdditionalButton ? [12, 6, 6, 3, 2] : 12
  const topPanelMinHeight = showAdditionalButton ? 60 : 40

  return (
    <>
      {!isEmpty && (
        <Box minHeight={topPanelMinHeight}>
          <Row>
            <Col
              mx="var(--ql-grid-gutter-negative)"
              cw={topPanelElementWidth}
              v={'center'}>
              <Text variant="body1" type="secondary">
                {`${customersCount} ` + t('customers found')}
              </Text>
            </Col>
            {showAdditionalButton && (
              <Col cw={topPanelElementWidth}>
                <Button
                  onClick={additionalAction}
                  loading={additionalActionLoading}>
                  {additionalText}
                </Button>
              </Col>
            )}
          </Row>
        </Box>
      )}
      <Row mx="var(--ql-grid-gutter-negative)">
        {isListWithCreate && actions?.onCreate && (
          <Col mb={4} cw={itemWidth}>
            <AddItemCard onClick={actions.onCreate} />
          </Col>
        )}
        {!isEmpty &&
          customers.map((customer, index) => (
            <Col mb={4} key={customer?._id || index} cw={itemWidth}>
              <RenderIfVisible defaultHeight="225px">
                <CustomerSimpleView
                  actions={actions}
                  index={index}
                  customer={customer}
                />
              </RenderIfVisible>
            </Col>
          ))}
        {isEmpty && (
          <Col cw={12}>
            <Text variant="overline" type="secondary">
              {emptyProps?.message || t('No customers have been created yet')}
            </Text>
          </Col>
        )}
      </Row>
    </>
  )
}

CustomerList.propTypes = {
  customers: PropTypes.array,
  isListWithCreate: PropTypes.bool,
  listView: PropTypes.bool,
  emptyProps: PropTypes.object,
  actions: PropTypes.any,
  cw: PropTypes.any,
  additionalAction: PropTypes.func,
  additionalActionLoading: PropTypes.bool,
  additionalText: PropTypes.string
}

export default CustomerList
