import { Box, Card, Col, Row, Text, Title } from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import { RenderIfVisible } from 'components'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

const CustomerWithoutPlanList = (props) => {
  const { customers, emptyProps, listView, cw = [12, 12, 6, 6, 4, 3] } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Counting the customers */
  const customersCount = customers?.length
  /* Computing item width */
  const itemWidth = listView ? 12 : cw

  /* Checking if list is empty */
  const isEmpty = useMemo(() => !customers?.length, [customers?.length])

  const getCustomerNameText = (customerFirstName, customerLastName) =>
    !customerFirstName && !customerLastName
      ? t('No name')
      : `${customerFirstName || ''} ${customerLastName || ''}`

  return (
    <>
      {!isEmpty && (
        <Box minHeight={40}>
          <Text variant="body1" type="secondary">
            {`${customersCount} ` + t('customers found')}
          </Text>
        </Box>
      )}
      <Row mx="var(--ql-grid-gutter-negative)">
        {!isEmpty &&
          customers.map((customer, index) => (
            <Col mb={2} key={customer?._id || index} cw={itemWidth}>
              <RenderIfVisible>
                <Card>
                  <Box display="flex" flexDirection="column">
                    <Title level={4}>
                      {getCustomerNameText(
                        customer?.firstName,
                        customer?.lastName
                      )}
                    </Title>
                    <Text type="secondary">{customer?.email}</Text>
                  </Box>
                </Card>
              </RenderIfVisible>
            </Col>
          ))}
        {isEmpty && (
          <Col cw={12}>
            <Text variant="overline" type="secondary">
              {emptyProps?.message || t('There is no customers without plan')}
            </Text>
          </Col>
        )}
      </Row>
    </>
  )
}

CustomerWithoutPlanList.propTypes = {
  customers: PropTypes.array,
  listView: PropTypes.bool,
  emptyProps: PropTypes.object,
  cw: PropTypes.any
}

export default CustomerWithoutPlanList
