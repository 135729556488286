import { Box, Button, Container, Text } from '@qonsoll/react-design'
import {
  useGetCustomerExtraTickets,
  useUpdateCustomerExtraTicketsAmount
} from 'domains/Customer/hooks'

import { Icon } from '@qonsoll/icons'
import { useParams } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'

const CustomerExtraTickets = () => {
  const { customerId } = useParams()

  const extraTickets = useGetCustomerExtraTickets(customerId)

  /* Getting translations instance */
  const { t } = useTranslations()

  const [updateExtraTicketsAmount, updateTicketsLoading] =
    useUpdateCustomerExtraTicketsAmount(customerId, extraTickets)

  const firstButtonTicketsAmount = 5
  const secondButtonTicketsAmount = 10

  return (
    <Container>
      <Text
        mr={2}
        color="var(--ql-form-item-label-color)"
        fontSize="var(--ql-form-item-label-font-size-base)">
        {`${t('Extra tickets left')}: ${extraTickets}`}
      </Text>
      <Box display="flex" mt={2} alignItems="center">
        <Button
          loading={updateTicketsLoading}
          block
          mr={2}
          onClick={() => updateExtraTicketsAmount(firstButtonTicketsAmount)}>
          {
            <Box display="flex" alignItems="center">
              <Icon name="Ticket1Outlined" mr={2} />
              <Text>{`+${firstButtonTicketsAmount} ${t('tickets')}`}</Text>
            </Box>
          }
        </Button>
        <Button
          loading={updateTicketsLoading}
          block
          onClick={() => updateExtraTicketsAmount(secondButtonTicketsAmount)}>
          {
            <Box display="flex" alignItems="center">
              <Icon name="Ticket1Outlined" mr={2} />
              <Text>{`+${secondButtonTicketsAmount} ${t('tickets')}`}</Text>
            </Box>
          }
        </Button>
      </Box>
    </Container>
  )
}

export default CustomerExtraTickets
