import { Box, Card, Skeleton, Title } from '@qonsoll/react-design'
import { COLLECTIONS, SUBSCRIPTION_STATUSES } from '__constants__'
import { Col, Row } from 'antd'

import { LoanStatistics } from 'domains/Loan/components'
import { PageLayout } from 'components'
import PropTypes from 'prop-types'
import { ROLES } from '__constants__/enums'
import { useCollection } from 'services/api/firebase'
import { useMemo } from 'react'
import { useTranslations } from 'contexts'

const Dashboard = () => {
  const { t } = useTranslations()

  return (
    <PageLayout title={t('Dashboard')}>
      <UserStatistics />
      <Box mb={4} />
      <SubscriptionStatistics />
      <Box mb={4} />
      <SubscriptionStatistics type="flipping" title="Flipping subscriptions" />
      <Box mb={4} />
      <SubscriptionStatistics type="package" title="Package subscriptions" />
      <Box mb={4} />
      <LoanStatistics />
    </PageLayout>
  )
}

const UserStatistics = () => {
  /* Getting translations instance */
  const { t } = useTranslations()

  const usersRef = useMemo(
    () => ({ ref: COLLECTIONS.USERS, where: [['role', '==', ROLES.CUSTOMER]] }),
    []
  )

  const [allUsers, loading] = useCollection(usersRef)

  const proUsers = useMemo(
    () => allUsers?.filter((user) => user?.isProAccess === true),
    [allUsers]
  )

  const unlimitedAccess = useMemo(
    () => allUsers?.filter((user) => user?.isUnlimitedUser === true),
    [allUsers]
  )

  const freeUsers = useMemo(
    () =>
      allUsers?.filter(
        (user) => !user?.isProAccess && !user?.isUnlimitedAccess
      ),
    [allUsers]
  )

  const items = useMemo(
    () => [
      { title: t('All customers'), value: allUsers?.length },
      { title: t('Pro customers'), value: proUsers?.length },
      { title: t('Unlimited customers'), value: unlimitedAccess?.length },
      { title: t('Free customers'), value: freeUsers?.length }
    ],
    [
      allUsers?.length,
      proUsers?.length,
      unlimitedAccess?.length,
      freeUsers?.length,
      t
    ]
  )

  if (loading) return <Skeleton />

  return (
    <Card title={t('Customers')}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {items.map((item, index) => (
          <Col flex="1" key={index}>
            <StatisticItem {...item} />
          </Col>
        ))}
      </Row>
    </Card>
  )
}

const SubscriptionStatistics = (props) => {
  const { type = 'rental', title = 'Rental subscriptions' } = props
  /* Getting translations instance */
  const { t } = useTranslations()

  const subscriptionsRef = useMemo(
    () =>
      type === 'rental'
        ? { ref: COLLECTIONS.SUBSCRIPTIONS }
        : {
            ref: COLLECTIONS.SUBSCRIPTIONS,
            where: [['subscriptionType', '==', type]]
          },
    [type]
  )

  const [allSubscriptions, loading] = useCollection(subscriptionsRef)

  const subscriptions = useMemo(
    () =>
      type === 'rental'
        ? allSubscriptions?.length
          ? allSubscriptions?.filter(
              (sub) =>
                sub?.subscriptionType === 'rental' || !sub?.subscriptionType
            )
          : []
        : allSubscriptions,
    [allSubscriptions, type]
  )

  const activeSubscriptions = useMemo(
    () =>
      subscriptions?.filter(
        (subscription) =>
          subscription?.status === SUBSCRIPTION_STATUSES.ACTIVE &&
          !subscription?.cancelAtPeriodEnd
      ),
    [subscriptions]
  )
  const trialingSubscriptions = useMemo(
    () =>
      subscriptions?.filter(
        (subscription) =>
          subscription?.status === SUBSCRIPTION_STATUSES.TRIALING
      ),
    [subscriptions]
  )
  const canceledSubscriptions = useMemo(
    () =>
      subscriptions?.filter(
        (subscription) =>
          subscription?.status === SUBSCRIPTION_STATUSES.CANCELED ||
          subscription?.cancelAtPeriodEnd
      ),
    [subscriptions]
  )
  const openSubscriptions = useMemo(
    () =>
      subscriptions?.filter(
        (subscription) => subscription?.status === SUBSCRIPTION_STATUSES.OPEN
      ),
    [subscriptions]
  )
  const incomplete = useMemo(
    () =>
      subscriptions?.filter(
        (subscription) =>
          subscription?.status === SUBSCRIPTION_STATUSES.INCOMPLETE
      ),
    [subscriptions]
  )

  const items = useMemo(
    () => [
      { title: t('All'), value: subscriptions?.length },
      { title: t('Active'), value: activeSubscriptions?.length },
      { title: t('Trialing'), value: trialingSubscriptions?.length },
      { title: t('Canceled'), value: canceledSubscriptions?.length },
      { title: t('Open'), value: openSubscriptions?.length },
      { title: t('Incomplete'), value: incomplete?.length }
    ],
    [
      subscriptions?.length,
      activeSubscriptions?.length,
      trialingSubscriptions?.length,
      canceledSubscriptions?.length,
      openSubscriptions?.length,
      incomplete?.length,
      t
    ]
  )

  if (loading) return <Skeleton />

  return (
    <Card title={t(title)}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {items.map((item, index) => (
          <Col flex="1" key={index}>
            <StatisticItem {...item} />
          </Col>
        ))}
      </Row>
    </Card>
  )
}

SubscriptionStatistics.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string
}

const StatisticItem = ({ title, value }) => {
  return (
    <Box
      bg="var(--ql-color-accent1-t-lighten7)"
      p={3}
      borderRadius="var(--card-radius)">
      <Title level={3}>{value}</Title>
      <>{title}</>
    </Box>
  )
}

StatisticItem.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number
}

export default Dashboard
