import PageHeader from '../PageHeader'
import PropTypes from 'prop-types'

const PageLayout = (props) => {
  const { children, header } = props

  return (
    <>
      {header || <PageHeader mb={4} {...props} />}
      {children}
    </>
  )
}

PageLayout.propTypes = {
  children: PropTypes.any,
  header: PropTypes.any
}

export default PageLayout
