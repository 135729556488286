const USERS = 'users'
const LOGS = 'logs'
const HELPS = 'helps'
const CUSTOMERS = 'users'
const RENTAL_CALCULATORS = 'rentalCalculators'
const TICKETS = 'tickets'
const SUBSCRIPTIONS = 'subscriptions'
const LOAN_APPLICATIONS = 'loanApplications'
const NEW_CUSTOMERS_DATE = 'newCustomersDate'

export const COLLECTIONS = {
  USERS,
  LOGS,
  HELPS,
  CUSTOMERS,
  RENTAL_CALCULATORS,
  TICKETS,
  SUBSCRIPTIONS,
  LOAN_APPLICATIONS,
  NEW_CUSTOMERS_DATE
}
export default COLLECTIONS
