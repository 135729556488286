import { Col, Row } from '@qonsoll/react-design'

import { CustomerAdvancedForm } from 'domains/Customer/components'
import { PageLayout } from 'components'
import { Spinner } from 'components'
import { useGetCustomerInitialValues } from 'domains/Customer/hooks'
import { useParams } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'

const CustomerEditPage = (props) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting params from router */
  const params = useParams()
  /* Getting id from params */
  const { customerId } = params

  /* Fetching customer initial values */
  const [initialValues, loading] = useGetCustomerInitialValues(customerId)

  return (
    <PageLayout
      title={t('Customer edit')}
      subTitle={t(
        'Follow the instructions bellow to get results in shortest possible time'
      )}>
      <Row noGutters>
        {loading ? (
          <Col>
            <Spinner height="70vh" text={t('Customer loading')} />
          </Col>
        ) : (
          <Col cw={[12, 12, 8, 7, 5]}>
            <CustomerAdvancedForm initialData={initialValues} />
          </Col>
        )}
      </Row>
    </PageLayout>
  )
}

export default CustomerEditPage
