import { Col, Row } from '@qonsoll/react-design'

import { HelpAdvancedForm } from 'domains/Help/components'
import { PageLayout } from 'components'
import { useTranslations } from 'contexts/Translation'

const HelpCreatePage = (props) => {
  /* Getting translations instance */
  const { t } = useTranslations()

  return (
    <PageLayout
      title={t('Help create')}
      subTitle={t(
        'Follow the instructions bellow to get results in shortest possible time'
      )}>
      <Row noGutters>
        <Col cw={[12, 12, 10, 8, 7]}>
          <HelpAdvancedForm />
        </Col>
      </Row>
    </PageLayout>
  )
}

export default HelpCreatePage
