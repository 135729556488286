import { AccountMenu, LayoutHeader, Logo, MainMenu } from './components'
import { Box, Layout, LayoutSystemProvider } from '@qonsoll/react-design'

import Back from '../Back'
import { LanguageSelect } from 'modules/admin-module/domains/Translation/components'
import PATHS from 'pages/paths'
import PropTypes from 'prop-types'
import { StyledAside } from './components/LayoutAside/LayoutAside.styled'
import { ThemeProvider } from 'styled-components'
import breakpoints from 'styles/app/breakpoints'
import { useAsideState } from './hooks'
import { useLocation } from 'react-router-dom'

const unauthenticatedPaths = Object.values(PATHS.UNAUTHENTICATED)

const BoilerplateLayout = ({ children }) => {
  const location = useLocation()
  const [asideLeftCollapsed, setCollapsed] = useAsideState()

  const handleAsideCollapse = () => setCollapsed((prev) => !prev)

  const isOnAuthPage = unauthenticatedPaths.includes(location.pathname)
  /* The layoutConfig is memoized to only re-render when isAuthenticated changes. */
  const layoutConfig = {
    isAsideLeft: !isOnAuthPage,
    asideLeftCollapseVariant: 'small',
    asideLeftCollapsed,
    asideLeftOuter: false,
    asideLeftOverlay: false,
    // Workaround for the issue with the layout system (to fix aside and content and make correct overflow for content)
    contentVerticalPadding: '0',
    contentHorizontalPadding: '0'
  }

  return (
    <ThemeProvider theme={breakpoints}>
      {!isOnAuthPage ? (
        <Box>
          <LayoutSystemProvider {...layoutConfig}>
            <Layout
              asideLeft={
                <StyledAside zIndex="3" backgroundColor="var(--aside-bg)">
                  <Box
                    mb={52}
                    cursor="pointer"
                    overflow="hidden"
                    display="flex"
                    justifyContent={asideLeftCollapsed && 'center'}
                    alignItems="center"
                    onClick={handleAsideCollapse}>
                    <Logo collapse={asideLeftCollapsed} />
                  </Box>
                  <Box mb="auto" overflowY="auto" mx={-24}>
                    <MainMenu collapse={asideLeftCollapsed} />
                  </Box>
                  <Box mb={2}>
                    <LanguageSelect collapse={asideLeftCollapsed} />
                  </Box>
                  <Box ml={asideLeftCollapsed ? 1 : 0}>
                    <AccountMenu collapse={asideLeftCollapsed} />
                  </Box>
                </StyledAside>
              }>
              <Box
                py={'var(--ql-content-py)'}
                bg="var(--ql-color-white)"
                px={[
                  'var(--ql-content-px-xxs)',
                  'var(--ql-content-px-xs)',
                  'var(--ql-content-px-sm)',
                  'var(--ql-content-px-md)',
                  'var(--ql-content-px-lg)',
                  'var(--ql-content-px-xl)',
                  'var(--ql-content-px-xxl)',
                  'var(--ql-content-px-xxxl)'
                ]}
                style={{
                  height: '100vh',
                  width: `calc(100vw - ${
                    asideLeftCollapsed
                      ? 'var(--ql-aside-collapsed-width)'
                      : 'var(--ql-aside-width)'
                  })`,
                  overflowY: 'auto'
                }}>
                {children}
              </Box>
            </Layout>
          </LayoutSystemProvider>
        </Box>
      ) : (
        <LayoutSystemProvider {...layoutConfig}>
          <Layout>
            <Box
              py={'var(--ql-content-py)'}
              px={[
                'var(--ql-content-px-xxs)',
                'var(--ql-content-px-xs)',
                'var(--ql-content-px-sm)',
                'var(--ql-content-px-md)',
                'var(--ql-content-px-lg)',
                'var(--ql-content-px-xl)',
                'var(--ql-content-px-xxl)',
                'var(--ql-content-px-xxxl)'
              ]}
              style={{
                height: '90vh',
                width: '100vw',
                overflow: 'hidden'
              }}>
              <LayoutHeader
                left={<Back />}
                center={
                  <Box display="flex" justifyContent="center">
                    <Logo />
                  </Box>
                }
              />
              {children}
            </Box>
          </Layout>
        </LayoutSystemProvider>
      )}
    </ThemeProvider>
  )
}

BoilerplateLayout.propTypes = {
  children: PropTypes.element
}

export default BoilerplateLayout
