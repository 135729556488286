import { CustomerEdit, CustomersAll } from './Customer'
import { HelpCreate, HelpEdit, HelpsAll } from './Help'

import Dashboard from './Dashboard/Dashboard'
import PATHS from '../paths'
import { Settings } from './Settings'
import { Statistics } from './Statistics'
import Translations from 'modules/admin-module/pages/Translations'

const {
  DASHBOARD,
  TRANSLATIONS,
  SETTINGS,
  STATISTICS,
  HELPS_ALL,
  HELP_CREATE,
  HELP_EDIT,
  CUSTOMERS_ALL,
  CUSTOMER_EDIT
} = PATHS.AUTHENTICATED

const MAIN_ROUTES = [
  { key: 'DASHBOARD', path: DASHBOARD, component: Dashboard, exact: true },
  {
    key: 'TRANSLATIONS',
    path: TRANSLATIONS,
    component: Translations,
    exact: true
  },
  {
    key: 'SETTINGS',
    path: SETTINGS,
    component: Settings,
    exact: false
  },
  {
    key: 'STATISTICS',
    path: STATISTICS,
    component: Statistics,
    exact: false
  },
  { key: 'HELP_EDIT', path: HELP_EDIT, component: HelpEdit, exact: true },
  {
    key: 'CUSTOMER_EDIT',
    path: CUSTOMER_EDIT,
    component: CustomerEdit,
    exact: true
  },
  { key: 'HELPS_ALL', path: HELPS_ALL, component: HelpsAll, exact: true },
  { key: 'HELP_CREATE', path: HELP_CREATE, component: HelpCreate, exact: true },
  {
    key: 'CUSTOMERS_ALL',
    path: CUSTOMERS_ALL,
    component: CustomersAll,
    exact: true
  }
]

const SETTINGS_ROUTES = []

const STATISTICS_ROUTES = []

export { MAIN_ROUTES, SETTINGS_ROUTES, STATISTICS_ROUTES }
export default MAIN_ROUTES
