import { Col, Row } from '@qonsoll/react-design'
import {
  CustomerList,
  CustomerWithoutPlanList,
  DownloadEmailListButton
} from 'domains/Customer/components'
import { Icon, PageLayout, Spinner } from 'components'
import { Input, Tabs } from 'antd'
import { useMemo, useState } from 'react'
import { Timestamp } from 'firebase/firestore'

import PropTypes from 'prop-types'
import {
  useGetCustomers,
  useGetNewCustomersDate,
  useUpdateNewCustomersDate
} from 'domains/Customer/hooks'
import { useStateWithStorage } from 'hooks'
import { useTranslations } from 'contexts/Translation'

const TAB_ID = 'customer-all-active-tab'

const CustomersAllPage = (props) => {
  const [activeTab, setActiveTab] = useStateWithStorage(null, TAB_ID)
  const [query, setQuery] = useState('')

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Fetching customers */
  const [customers, loading] = useGetCustomers()
  const newCustomersDate = useGetNewCustomersDate()
  const [updateNewCustomersDate, updateNewCustomersDateLoading] =
    useUpdateNewCustomersDate()

  /* Handling change tab */
  const changeTab = (key) => setActiveTab(key)

  /* The date to filter new customers */
  const newCustomersDateTimestamp = useMemo(() => {
    return Timestamp.fromDate(new Date(newCustomersDate))
  }, [newCustomersDate])

  /* Array of all customers regarding filtering conditions */
  const filteredCustomers = useMemo(() => {
    if (!query) return customers
    return customers?.filter(
      (customer) =>
        customer?.firstName?.toLowerCase().includes(query?.toLowerCase()) ||
        customer?.lastName?.toLowerCase().includes(query?.toLowerCase()) ||
        customer?.email?.toLowerCase().includes(query?.toLowerCase())
    )
  }, [customers, query])

  /* Array of customers without a paid plan */
  const freeCustomers = useMemo(
    () =>
      filteredCustomers?.filter(
        (customer) =>
          !customer.subscriptions?.rental &&
          !customer.subscriptions?.flipping &&
          !customer.subscriptions?.package
      ),
    [filteredCustomers]
  )

  /* Array of new customers, created after a defined date */
  const newCustomers = useMemo(
    () =>
      filteredCustomers?.filter(
        (customer) => customer._createdAt > newCustomersDateTimestamp
      ),
    [filteredCustomers, newCustomersDateTimestamp]
  )

  /* Array of selected customers to download in CSV file */
  const customersToDownload = (activeTabKey) => {
    switch (activeTabKey) {
      case '#customers': {
        return filteredCustomers
      }
      case '#freeCustomers': {
        return freeCustomers
      }
      case '#newCustomers': {
        return newCustomers
      }
      default: {
        return []
      }
    }
  }

  return (
    <PageLayout
      title={t('Customers')}
      subTitle={t(
        'Follow the instructions bellow to get results in shortest possible time'
      )}
      actions={
        <Input
          prefix={
            <Icon
              name="SearchLgOutlined"
              fill="var(--input-placeholder-color)"
              size={18}
            />
          }
          onChange={(e) => setQuery(e.target.value)}
          placeholder={t('Search by name, email') + '...'}
        />
      }>
      <Row noGutters>
        {loading ? (
          <Col cw={12}>
            <Spinner height="70vh" text={t('Customer loading')} />
          </Col>
        ) : (
          <Col cw={12}>
            <Tabs
              tabBarExtraContent={{
                right: (
                  <DownloadEmailListButton
                    customers={customersToDownload(activeTab)}
                  />
                )
              }}
              defaultActiveKey={activeTab}
              onChange={changeTab}
              style={{ overflow: 'unset' }}>
              <Tabs.TabPane tab={t('All customers')} key="#customers">
                <CustomerList customers={filteredCustomers} />
              </Tabs.TabPane>
              <Tabs.TabPane tab={t('Free customers')} key="#freeCustomers">
                <CustomerWithoutPlanList customers={freeCustomers} />
              </Tabs.TabPane>
              <Tabs.TabPane tab={t('New customers')} key="#newCustomers">
                <CustomerList
                  customers={newCustomers}
                  additionalAction={updateNewCustomersDate}
                  additionalActionLoading={updateNewCustomersDateLoading}
                  additionalText={t('Mark as watched')}
                />
              </Tabs.TabPane>
            </Tabs>
          </Col>
        )}
      </Row>
    </PageLayout>
  )
}

CustomersAllPage.propTypes = {
  isListWithCreate: PropTypes.bool,
  withHeader: PropTypes.bool
}

export default CustomersAllPage
