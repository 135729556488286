import { Img } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import longLogo from 'assets/logo/logo-full.svg'
import shortLogo from 'assets/logo/logo-symbol.svg'

const Logo = ({ collapse }) => {
  return (
    <Img
      src={collapse ? shortLogo : longLogo}
      width={collapse ? '40px' : '160px'}
      alt="Qonsoll"
      height="40"
    />
  )
}

Logo.propTypes = {
  collapse: PropTypes.bool
}

export default Logo
