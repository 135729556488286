import { model, attr } from 'services/model'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  avatarUrl: yup
    .string()
    .typeError('Field avatarUrl has wrong type')
    .default(null)
    .nullable(),
  firstName: yup
    .string()
    .typeError('Field firstName has wrong type')
    .required('Field firstName is required'),
  lastName: yup
    .string()
    .typeError('Field lastName has wrong type')
    .required('Field lastName is required'),
  isProAccess: yup
    .boolean()
    .typeError('Field isProAccess has wrong type')
    .default(null)
    .nullable()
})

const Customer = model(
  'customer',
  {
    avatarUrl: attr('string'),
    firstName: attr('string'),
    lastName: attr('string'),
    isProAccess: attr('boolean')
  },
  validationSchema
)

export default Customer
