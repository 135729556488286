import { Col, Row, Text } from '@qonsoll/react-design'

import { AddItemCard } from 'components'
import { HelpSimpleView } from 'domains/Help/components'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const HelpList = (props) => {
  const {
    helps,
    isListWithCreate,
    listView,
    emptyProps,
    actions,
    cw = [12]
  } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Checking if list is empty */
  const isEmpty = !helps?.length
  /* Computing item width */
  const itemWidth = listView ? 12 : cw

  return (
    <Row mx="var(--ql-grid-gutter-negative)">
      {isListWithCreate && actions?.onCreate && (
        <Col mb={4} cw={itemWidth}>
          <AddItemCard onClick={actions.onCreate} />
        </Col>
      )}
      {!isEmpty &&
        helps.map((help, index) => (
          <Col key={help?._id || index} cw={itemWidth}>
            <HelpSimpleView
              isLast={index === helps.length - 1}
              actions={actions}
              index={index}
              help={help}
            />
          </Col>
        ))}
      {isEmpty && (
        <Col cw={12}>
          <Text variant="overline" type="secondary">
            {emptyProps?.message || t('No helps have been created yet')}
          </Text>
        </Col>
      )}
    </Row>
  )
}

HelpList.propTypes = {
  helps: PropTypes.array,
  isListWithCreate: PropTypes.bool,
  listView: PropTypes.bool,
  emptyProps: PropTypes.object,
  actions: PropTypes.any,
  cw: PropTypes.any
}

export default HelpList
