import {
  useActionsHelpAdvancedForm,
  useInitializeHelpAdvancedForm
} from './hooks'

import { Button } from '@qonsoll/react-design'
import { Form } from 'antd'
import { HelpSimpleForm } from 'domains/Help/components'
import { KEYS } from '__constants__'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const HelpAdvancedForm = (props) => {
  const { initialData } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting form instance from hook */
  const { form } = useInitializeHelpAdvancedForm(initialData)

  /* Handling enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      onFinish()
    }
  }

  /* Getting loading state and actions from hook */
  const { loading, onFinish, onReset } = useActionsHelpAdvancedForm({
    initialData,
    form
  })

  return (
    <Form
      onKeyPress={handleKeyPress}
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      form={form}>
      <HelpSimpleForm group={['help']} />
      <Button mr={3} htmlType="button" onClick={onReset} mb={4}>
        {t('Cancel')}
      </Button>
      <Button type="primary" loading={loading} onClick={() => form.submit()}>
        {t('Save')}
      </Button>
    </Form>
  )
}

HelpAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool
}

export default HelpAdvancedForm
