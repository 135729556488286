import { Box, Divider, Menu, MenuItem } from '@qonsoll/react-design'
import { Fragment, cloneElement, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const MainMenu = ({ collapse }) => {
  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const history = useHistory()
  const location = useLocation()

  // [COMPUTED_PROPERTIES]
  const selected = useMemo(() => {
    const currentParentPath = location.pathname.split('/')?.[1]
    return ['/' + currentParentPath]
  }, [location])

  const menuItems = useMemo(
    () => [
      {
        key: '/dashboard',
        icon: <Icon name="DashboardOutlined" />,
        text: t('Dashboard'),
        onClick: () => history.push('/dashboard')
      },
      {
        key: '/customers',
        icon: <Icon name="Users2Outlined" />,
        text: t('Customers'),
        onClick: () => history.push('/customers')
      },
      {
        key: '/translations',
        icon: <Icon name="Translate1Outlined" />,
        text: t('Translations'),
        onClick: () => history.push('/translations')
      },
      {
        key: '/helps',
        icon: <Icon name="LifeBuoy2Outlined" />,
        text: t('Helps'),
        onClick: () => history.push('/helps')
      }
    ],
    [t, history]
  )

  return (
    <Fragment>
      <Menu inlineCollapsed={collapse} mode="inline" selectedKeys={selected}>
        {menuItems.map((item) => (
          <Fragment key={item.key}>
            <MenuItem
              key={item.key}
              onClick={item.onClick}
              icon={cloneElement(item.icon, {
                fill: selected.includes(item.key)
                  ? 'var(--ql-menu-item-active-color)'
                  : 'var(--ql-menu-item-color)',
                size: '16px'
              })}>
              {item.text}
            </MenuItem>
            {item.divided && (
              <Box my={3}>
                <Divider />
              </Box>
            )}
          </Fragment>
        ))}
      </Menu>
    </Fragment>
  )
}

MainMenu.propTypes = {
  collapse: PropTypes.bool
}

export default MainMenu
