import { Col, Row, Title } from '@qonsoll/react-design'

import { LANGUAGES } from '../../__constants__'
import LocalizationItem from '../LocalizationItem'
import PropTypes from 'prop-types'
import { useGetTranslations } from '../../hooks'
import { useTranslations } from 'contexts/Translation'

const LocalizationForm = (props) => {
  const { appName } = props

  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()
  const [translations, loading] = useGetTranslations(appName)

  return (
    <>
      <Row mb={2} noOuterGutters>
        {LANGUAGES.map((lang) => (
          <Col key={lang.shortCode}>
            <Title level={5}>{t(lang.name)}</Title>
          </Col>
        ))}
      </Row>

      <Row mb={2} noOuterGutters v="center">
        <Col>
          {!loading &&
            translations.map((translation, index) => (
              <LocalizationItem
                key={`${appName}-${index}`}
                appName={appName}
                data={translation}
              />
            ))}
        </Col>
      </Row>
    </>
  )
}

LocalizationForm.propTypes = {
  appName: PropTypes.string.isRequired,
  isPageLoading: PropTypes.bool,
  handleLoadingChange: PropTypes.func
}

export default LocalizationForm
