import { ref, set } from 'firebase/database'
import { useCallback, useState } from 'react'

import { COLLECTIONS } from '__constants__'
import { database } from 'services/firebase'
import moment from 'moment'
import { message } from 'antd'
import { useTranslations } from 'contexts/Translation'

/**
 * It updates a new customers date by the current value
 */
const useUpdateNewCustomersDate = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const { t } = useTranslations()

  const updateNewCustomersDate = useCallback(async () => {
    try {
      setLoading(true)
      const ticketsRef = ref(database, COLLECTIONS.NEW_CUSTOMERS_DATE)
      await set(ticketsRef, moment.utc().format())
    } catch (err) {
      message.error(
        t(
          'Error during new customers date add operation. Check logs and contact administrator with this proper'
        )
      )
      //eslint-disable-next-line
      console.error('Error:', err)
      setError(err)
    } finally {
      setLoading(false)
    }
  }, [t])

  return [updateNewCustomersDate, loading, error]
}

export default useUpdateNewCustomersDate
