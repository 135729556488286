import { Box, Text, Title } from '@qonsoll/react-design'
import { Empty, Spinner } from 'components'
import {
  Timestamp,
  collection,
  getDocs,
  query,
  where
} from 'firebase/firestore'
import { useEffect, useState } from 'react'

import { COLLECTIONS } from '__constants__'
import { Modal } from 'antd'
import PropType from 'prop-types'
import { firestore } from 'services/firebase'
import moment from 'moment'
import { useTranslations } from 'contexts/Translation'

const LoanStatisticsDetailsModal = (props) => {
  const { date, title, value, children, valueMargin } = props
  const [visibility, setVisibility] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loanApplicationDetails, setLoanApplicationDetails] = useState([])

  const { t } = useTranslations()

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const docs = await getDocs(
          query(
            collection(firestore, COLLECTIONS.LOAN_APPLICATIONS),
            where(
              'sendDate',
              '>=',
              Timestamp.fromDate(date.startOf('month').toDate())
            ),
            where(
              'sendDate',
              '<=',
              Timestamp.fromDate(date.endOf('month').toDate())
            )
          )
        )
        setLoanApplicationDetails(docs?.docs?.map((item) => item.data()) || [])
      } catch (err) {
        // eslint-disable-next-line
    console.error('ERROR:', err)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [date])

  return (
    <>
      <Box
        style={{ cursor: 'pointer' }}
        onClick={() => setVisibility(true)}
        bg="var(--ql-color-accent1-t-lighten7)"
        p="18px"
        borderRadius="var(--card-radius)">
        <Title ml={valueMargin} level={3}>
          {value}
        </Title>
        <>{title}</>
        {children}
      </Box>
      <Modal
        bodyStyle={{ borderRadius: 'var(--card-radius)', minHeight: '500px' }}
        destroyOnClose
        visible={visibility}
        maskClosable
        onCancel={() => setVisibility(false)}
        footer={null}
        title={t('Loan application details')}>
        {loading ? (
          <Spinner text={t('Loan application details loading')} />
        ) : loanApplicationDetails?.length ? (
          loanApplicationDetails?.map(
            ({ firstName, lastName, _id, sendDate }) => (
              <Box mb={3} key={_id}>
                <Text>{`${moment(sendDate.toDate()).format(
                  'HH:mm:ss DD.MM.YYYY'
                )} - ${firstName} ${lastName}`}</Text>
              </Box>
            )
          )
        ) : (
          <Empty
            withCreate={false}
            message={t('No loan applications details found')}
          />
        )}
      </Modal>
    </>
  )
}

LoanStatisticsDetailsModal.propTypes = {
  date: PropType.object,
  title: PropType.string,
  value: PropType.string,
  children: PropType.node,
  valueMargin: PropType.number
}

export default LoanStatisticsDetailsModal
