import { Form } from 'antd'
import { Input } from '@qonsoll/react-design'
import { MAX_LENGTH_FIELD } from '__constants__'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const HelpSimpleForm = (props) => {
  const { onSubmit, group } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  return (
    <>
      <Form.Item
        label={t('Question')}
        name={[...group, 'question']}
        rules={[
          {
            required: true,
            message: t('Please enter your help question!')
          },
          { max: MAX_LENGTH_FIELD, message: t('help question is too long!') }
        ]}>
        <Input
          placeholder={t('Please enter your help question')}
          onPressEnter={onSubmit}
        />
      </Form.Item>
      <Form.Item
        label={t('Answer')}
        name={[...group, 'answer']}
        rules={[
          {
            required: true,
            message: t('Please enter your help answer!')
          },
          { max: MAX_LENGTH_FIELD, message: t('help answer is too long!') }
        ]}>
        <Input placeholder={t('Please enter your help answer')} />
      </Form.Item>
    </>
  )
}

HelpSimpleForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  showTitle: PropTypes.bool,
  form: PropTypes.any,
  group: PropTypes.array
}

export default HelpSimpleForm
