import { COLLECTIONS, DATE_FORMATS } from '__constants__'
import { Card, Skeleton } from '@qonsoll/react-design'
import { Col, DatePicker, Row } from 'antd'
import { onValue, ref } from 'firebase/database'
import { useEffect, useMemo, useState } from 'react'

import { LoanStatisticsDetailsModal } from 'domains/Loan/components'
import { database } from 'services/firebase'
import moment from 'moment'
import { useTranslations } from 'contexts/Translation'

const LoanStatistics = () => {
  const [loanApplications, setLoanApplications] = useState()
  const [selectedCustomDate, setSelectedCustomDate] = useState(
    moment().subtract(2, 'months')
  )
  const [loading, setLoading] = useState(false)
  const { t } = useTranslations()

  useEffect(() => {
    let isMount = true
    setLoading(true)
    const unsubscribe = onValue(
      ref(database, COLLECTIONS.LOAN_APPLICATIONS),
      (snapshot) => {
        const data = snapshot.val()
        if (isMount) {
          setLoanApplications(data)
          setLoading(false)
        }
      }
    )

    return () => {
      unsubscribe?.()
      isMount = false
    }
  }, [])

  const items = useMemo(() => {
    const nowDate = moment()
    const prevMonthDate = nowDate.clone()

    return [
      {
        title: t('This month'),
        value:
          loanApplications?.[nowDate.year()]?.[nowDate?.format('MMM')] || 0,
        date: nowDate.clone()
      },
      {
        title: t('Previous month'),
        date: prevMonthDate.subtract(1, 'month'),
        value:
          loanApplications?.[prevMonthDate.year()]?.[
            prevMonthDate.format('MMM')
          ] || 0
      }
    ]
  }, [loanApplications, t])

  if (loading) return <Skeleton />
  return (
    <Card title={t('Loan applications')}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {items.map(({ title, value, date }, index) => (
          <Col flex="1" key={index}>
            <LoanStatisticsDetailsModal
              title={title}
              value={value}
              date={date}
            />
          </Col>
        ))}
        <Col flex="1">
          <LoanStatisticsDetailsModal
            title={null}
            valueMargin={2}
            value={
              loanApplications?.[selectedCustomDate?.format('YYYY')]?.[
                selectedCustomDate?.format('MMM')
              ] || 0
            }
            date={selectedCustomDate}>
            <DatePicker
              disabledDate={(current) => current && current > moment()}
              value={selectedCustomDate}
              onClick={(e) => e.stopPropagation()}
              onChange={(newValue) => setSelectedCustomDate(newValue)}
              size="small"
              bordered={false}
              picker="month"
              format={DATE_FORMATS.SHORT_CODE_MONTH_AND_YEAR}
            />
          </LoanStatisticsDetailsModal>
        </Col>
      </Row>
    </Card>
  )
}

export default LoanStatistics
