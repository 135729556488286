import { Grid } from 'antd'
import { useStateWithStorage } from 'hooks'

const { useBreakpoint } = Grid

const useAsideState = () => {
  const { lg } = useBreakpoint()
  const [asideCollapsed, setCollapsed] = useStateWithStorage(
    false,
    'aside_visibility'
  )

  return [!lg ? true : asideCollapsed, setCollapsed]
}

export default useAsideState
