import { onValue, ref } from 'firebase/database'
import { useEffect, useState } from 'react'

import { COLLECTIONS } from '__constants__'
import { database } from 'services/firebase'

/**
 * It returns a date and time to filter new customers
 * @returns A date and time as string.
 */
const useGetNewCustomersDate = () => {
  const [newCustomersDate, setNewCustomersDate] = useState(null)

  useEffect(() => {
    let isMount = true

    const subscribe = async () => {
      const dbRef = ref(database, COLLECTIONS.NEW_CUSTOMERS_DATE)
      return onValue(dbRef, (snapshot) => {
        const date = snapshot.val()
        date && setNewCustomersDate(date)
      })
    }

    const unsubscribe = subscribe()
    return () => {
      isMount && unsubscribe?.off?.()
      isMount = false
    }
  })

  return newCustomersDate
}

export default useGetNewCustomersDate
