import { onValue, ref } from 'firebase/database'
import { useEffect, useState } from 'react'

import { COLLECTIONS } from '__constants__'
import { database } from 'services/firebase'

const useGetCustomerExtraTickets = (customerId) => {
  const [extraTickets, setExtraTickets] = useState(0)

  useEffect(() => {
    let isMount = true

    const subscribe = async () => {
      const dbRef = ref(database, `${COLLECTIONS.TICKETS}/${customerId}`)
      return onValue(dbRef, (snapshot) => {
        const data = snapshot.val()

        const extra = data?.extra || 0

        data && setExtraTickets(extra)
      })
    }

    const unsubscribe = customerId ? subscribe() : null
    return () => {
      isMount && unsubscribe?.off?.()
      isMount = false
    }
  }, [customerId])

  return extraTickets
}

export default useGetCustomerExtraTickets
