import { COLLECTIONS, TICKET_VALUES } from '__constants__'
import {
  createDocument,
  getDocument,
  getId,
  updateDocument,
  updateParent
} from 'services/api/firebase'
import { get, ref, set } from 'firebase/database'

import { database } from 'services/firebase'
import { fetchApi } from 'services/api/rest'
import { message } from 'antd'
import { uploadImage } from 'helpers'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const useActionsCustomerAdvancedForm = ({ initialData, form } = {}) => {
  /* State for loading */
  const [loading, setLoading] = useState(false)
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  /* Getting history state */
  const historyState = history.location.state

  const getCustomerInitialTickets = async (customerId) => {
    const userFB = await getDocument(COLLECTIONS.USERS, customerId)
    if (!userFB?.subscriptionId) return 0

    try {
      const subscriptionFB = await getDocument(
        COLLECTIONS.SUBSCRIPTIONS,
        userFB?.subscriptionId
      )
      const priceId = subscriptionFB?.priceId
      const res = await fetchApi({
        method: 'GET',
        path: `subscriptions/prices/${priceId}`
      })
      const initialTickets = res?.data?.price?.metadata?.tickets
      return initialTickets || 0
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log({ error })
      return 0
    }
  }

  const updateUnlimitedStatusTickets = async (unlimitedStatus, customerId) => {
    const initialTickets = await getCustomerInitialTickets(customerId)

    const subscriptionSnapshot = await get(
      ref(database, `${COLLECTIONS.TICKETS}/${customerId}`)
    )
    if (subscriptionSnapshot.exists()) {
      // const subscriptionValue = subscriptionSnapshot.val().subscription
      await set(ref(database, `${COLLECTIONS.TICKETS}/${customerId}`), {
        ...subscriptionSnapshot.val(),
        subscription: unlimitedStatus
          ? TICKET_VALUES.UNLIMITED_TICKET_VALUE
          : initialTickets
      })
    } else
      await set(ref(database, `${COLLECTIONS.TICKETS}/${customerId}`), {
        subscription: unlimitedStatus
          ? TICKET_VALUES.UNLIMITED_TICKET_VALUE
          : initialTickets
      })
  }

  /* Function for preparing values for saving */
  const prepareValues = async (values = {}, additionalValues = {}) => {
    /* Getting id */
    const customerId =
      initialData?.['customer']?._id || getId(COLLECTIONS.CUSTOMERS)

    /* Preparing customer values */
    const preparedValues = {
      _id: customerId,
      ...additionalValues,
      avatarUrl: (await uploadImage(values['customer']?.['avatarUrl'])) ?? null,
      firstName: values['customer']?.['firstName'] ?? null,
      lastName: values['customer']?.['lastName'] ?? null,
      isUnlimitedUser: values['customer']?.['isUnlimitedUser'] ?? null,
      // isProAccess: values['customer']?.['isProAccess'] ?? null,
      email: values['customer']?.['email'] ?? null,
      phone: values['customer']?.['phone'] ?? null
    }
    return preparedValues
  }

  /* Saving form data */
  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)

      if (historyState?.parent)
        await updateParent(historyState.parent, data._id)

      // Save data
      if (initialData) {
        await updateDocument(
          COLLECTIONS.CUSTOMERS,
          initialData.customer?._id,
          data
        )
        typeof data?.isUnlimitedUser === 'boolean' &&
          (await updateUnlimitedStatusTickets(
            data?.isUnlimitedUser,
            initialData.customer?._id
          ))
        message.success(t('Customer successfully updated'))
      } else {
        await createDocument(COLLECTIONS.CUSTOMERS, data, data._id)
        message.success(t('Customer successfully created'))
      }
      // Final callback
      callback?.()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Something went wrong during data save'))
    }
  }

  /* On finish callback */
  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()
      // Save data
      await saveForm(formValues)
      // Final callback
      history.goBack()
    } catch (error) {
      setLoading(false)
      message.error(error.message)
    }
  }

  /* On cancel callback */
  const onReset = () => {
    form.resetFields()
    history.goBack()
  }

  return { onFinish, onReset, loading, saveForm, prepareValues }
}

export default useActionsCustomerAdvancedForm
