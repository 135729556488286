import { useEffect, useState } from 'react'
import { useGDPRStatus, useSessionActions } from 'domains/Session/hooks'

import { COLLECTIONS } from '__constants__'
import firebase from 'firebase/compat/app'
import { onAuthStateChanged } from 'firebase/auth'
import { useHandleError } from 'hooks'

const useInitializeUser = () => {
  /* If the user is logged in, fetch the user's data from Firestore. */
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  /* The above code is a function that takes in a callback function as an argument.
  The callback function is then called with the error as an argument. */
  const handleError = useHandleError()

  /* Using the useGDPRStatus hook to get the GDPR status of the user. */
  const gdpr = useGDPRStatus()

  // Session methods
  const { saveUserToDB } = useSessionActions()

  useEffect(() => {
    let unsubscribeUserListener = null
    const unsubscribeAuthListener = onAuthStateChanged(
      firebase.auth(),
      (currentAuth) => {
        if (currentAuth) {
          const { uid, email, photoURL, displayName } = currentAuth

          unsubscribeUserListener = firebase
            .firestore()
            .collection(COLLECTIONS.USERS)
            .doc(currentAuth.uid)
            .onSnapshot({
              next: (snapshot) => {
                if (!snapshot?.exists) {
                  return saveUserToDB({
                    gdpr,
                    id: uid,
                    email: email,
                    avatarUrl: photoURL,
                    agreement: true,
                    onError: handleError,
                    additionalData: { displayName }
                  })
                }
                const data = snapshot.data()
                setUser(data)
                setLoading(false)
              },
              error: setError
            })
        } else {
          setUser(null)
          setLoading(false)
          unsubscribeUserListener?.()
        }
      }
    )

    return () => {
      unsubscribeAuthListener?.()
      unsubscribeUserListener?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [user, loading, error]
}

export default useInitializeUser
