import {
  Box,
  Col,
  Divider,
  Link,
  Row,
  Text,
  Title
} from '@qonsoll/react-design'

import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { useHelpBaseActions } from 'domains/Help/hooks'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const HelpSimpleView = (props) => {
  const { help, isLast } = props
  const { question, answer } = help

  const [isCollapsed, setIsCollapsed] = useState(true)

  /* Getting translations instance */
  const { t } = useTranslations()
  /* State for controlling list view with localStorage remembering */
  const toggle = () => setIsCollapsed((prev) => !prev)
  /* Getting base actions */
  const { onEdit, onDelete } = useHelpBaseActions({
    helpId: help?._id
  })

  return (
    <Row noGutters v="start">
      <Col cw={[12, 12, 8]}>
        <Box display="flex" alignItems="center" mb={3}>
          <Icon
            name={isCollapsed ? 'MinusOutlined' : 'PlusOutlined'}
            fill="var(--ql-color-accent1)"
            onClick={toggle}
            cursor="pointer"
            size={20}
            mr={3}
          />
          <Title level={3} cursor="pointer" onClick={toggle}>
            {question}
          </Title>
        </Box>
        {isCollapsed && (
          <Box pl={36} mb={3}>
            <Text>{answer}</Text>
          </Box>
        )}
      </Col>
      <Col cw={[12, 12, 'auto']} ml={[0, 0, 'auto']} mb={[3, 3, 0]}>
        <Box pl={[36, 36, 0]} display="flex" alignItems="center">
          <Link onClick={onEdit}>{t('Edit')}</Link>
          <Divider mx={3} type="vertical" />
          <Link type="danger" onClick={onDelete}>
            {t('Delete')}
          </Link>
        </Box>
        {/* <Button icon={<Icon name="Edit1Outlined" />} mr={2} />
        <Remove
          icon={<Icon fill="#fff" name="Trash1Outlined" />}
          type="primary"
        /> */}
        {/* <Button danger icon={<Icon name="Trash1Outlined" />} /> */}
      </Col>
      {!isLast && (
        <Col cw={12} mb={3}>
          <Divider />
        </Col>
      )}
    </Row>
  )
}

HelpSimpleView.propTypes = {
  help: PropTypes.object,
  isLast: PropTypes.bool
}

export default HelpSimpleView
