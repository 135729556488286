import { COLLECTIONS } from '__constants__'
import { ROLES } from '__constants__/enums'
import { useCollection } from 'services/api/firebase'
import { useMemo } from 'react'

/**
 * It returns an array of values that are used to render the customers
 * @param props - An object that contains the query parameters.
 * @returns An array of values.
 */
const useGetCustomers = (props) => {
  /* Query for getting collection reference */
  const query = useMemo(() => {
    const { where: PropsWhere, ...rest } = props || {}
    const where = [['role', '==', ROLES.CUSTOMER], ...(PropsWhere || [])]

    return { ref: COLLECTIONS.USERS, where: where, ...rest }
  }, [props])

  /* Getting collection data */
  const [value, loading, error, next, loadingMore, loadMoreAvailable] =
    useCollection(query)

  return [value, loading, error, next, loadingMore, loadMoreAvailable]
}

export default useGetCustomers
